<script setup>
import {useBaseStore} from "~/stores/base";
import {PATH_LOGIN, PATH_REGISTER} from "~/utils/constants";
import {ELEMENT_LABEL, ELEMENT_TITLE, ELEMENT_TYPE} from "~/utils/smartlook";

const route = useRoute()

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-login-entrance-mobile" v-if="isMobile">
    <div class="hello">{{ $t("popoverLoginHello") }}</div>
    <div class="subtitle">{{ $t("popoverSubtitle1") }}</div>
    <nuxt-link class="button" :to="`${PATH_LOGIN}?redirect_uri=${encodeURIComponent(route.fullPath)}`">
      <img src="@/assets/img/icon-user-filled.svg" alt="user">
      login
    </nuxt-link>
    <div class="subtitle">{{ $t("popoverSubtitle2") }}</div>
    <nuxt-link class="button" :to="PATH_REGISTER">
      <img src="@/assets/img/icon-flash.svg" alt="flash">
      {{ $t("headerJoinNow") }}
    </nuxt-link>
  </div>
  <div class="module-login-entrance-desktop" v-else>
    <div class="hello">{{ $t("popoverLoginHello") }}</div>
    <div class="subtitle">{{ $t("popoverSubtitle1") }}</div>
    <nuxt-link
      class="button"
      :to="`${PATH_LOGIN}?redirect_uri=${encodeURIComponent(route.fullPath)}`"
      element-expose-observe
      element-click-observe
      :element-type="ELEMENT_TYPE.BUTTON"
      :element-title="ELEMENT_TITLE.LOGIN"
      :element-label="ELEMENT_LABEL.ACCOUNT"
    >
      <img src="@/assets/img/icon-user-filled.svg" alt="user">
      login
    </nuxt-link>
    <div class="subtitle">{{ $t("popoverSubtitle2") }}</div>
    <nuxt-link
      class="button"
      :to="PATH_REGISTER"
      element-expose-observe
      element-click-observe
      :element-type="ELEMENT_TYPE.BUTTON"
      :element-title="ELEMENT_TITLE.JOIN_NOW"
      :element-label="ELEMENT_LABEL.ACCOUNT"
    >
      <img src="@/assets/img/icon-flash.svg" alt="flash">
      {{ $t("headerJoinNow") }}
    </nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-login-entrance-mobile {
  background-color: white;
  padding: 32px 16px 42px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .hello {
    //styleName: Headline/small;
    font-family: Alternate Gothic No3 D;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .subtitle {
    //styleName: Text xs/Regular;
    font-family: TWK Lausanne;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;

    margin-top: 16px;
  }

  .button {
    cursor: pointer;
    width: 343px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-BNTO-beige-light;

    text-align: center;
    /* Button/large */
    font-family: "Druk Wide Cy";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px; /* 83.333% */
    text-transform: uppercase;

    border: 0.5px $color-BNTO-beige-dark solid;

    margin-top: 16px;

    img {
      pointer-events: none;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}

.module-login-entrance-desktop {
  background-color: white;
  padding: 32px 16px 42px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .hello {
    //styleName: Headline/small;
    font-family: Alternate Gothic No3 D;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .subtitle {
    //styleName: Text xs/Regular;
    font-family: TWK Lausanne;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;

    margin-top: 16px;
  }

  .button {
    cursor: pointer;
    width: 343px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-BNTO-beige-light;

    text-align: center;
    /* Button/large */
    font-family: "Druk Wide Cy";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px; /* 83.333% */
    text-transform: uppercase;

    border: 0.5px $color-BNTO-beige-dark solid;

    margin-top: 16px;

    img {
      pointer-events: none;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
</style>
